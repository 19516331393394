@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
::-webkit-input-placeholder {
  align-self: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  align-self: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  align-self: center;
}

:-ms-input-placeholder {
  align-self: center;
}
:root {
  --primaryColor: #1f96e6;
  --secColor: white;
  --terColor: #bb9416;
  --acceptGreen: #9ac168;
  --rejectRed: #fbd9d9;
  --hoverColor: #d0e7f7;
  --primaryRGBA: rgba(0, 0, 0, 0.3);
  --mainGrey: #fcfcfc;
  --darkGrey: #8d8989;
  --mainWhite: #ffff;
  --appGrey: rgba(202, 199, 199, 0.144);
  --mainBlack: #2222;
  --mainTransition: all 0.3s ease-in-out;
  --mainSpacing: 0.15em;
  --chatHover: #fcfcfc;
}

.main-side-bar {
  background-color: var(--secColor) !important;
  /* border-right:2px solid var(--primaryColor) !important; */
  box-shadow: 5px 1px 5px 0px rgba(202, 199, 199, 0.144);
  -webkit-box-shadow: 5px 1px 5px 0px rgba(202, 199, 199, 0.144);
  -moz-box-shadow: 5px 1px 5px 0px rgba(202, 199, 199, 0.144);
  padding: 10px !important;
  font-family: "Cairo";
  font-weight: bolder;
}

.scroller-side-bar {
  overflow-y: auto;
  max-height: 70vh !important;
}

.side-bar-link {
  color: var(--primaryColor) !important;
  font-weight: bold;
  border-radius: 10px !important;
  font-family: "Cairo";
  background-color: var(--appGrey);
}

.side-bar-link:hover {
  color: var(--secColor) !important;
  background-color: var(--primaryColor) !important;
  font-family: "Cairo";
}

.login-page {
  background-color: var(--mainGrey) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Cairo";
}

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 1.1em solid var(--primaryColor);
  border-left: 1.1em solid var(--hoverColor);
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-form {
  background-color: var(--primaryColor) !important;
  padding: 80px;
  border-radius: 20px;
  font-family: "Cairo";
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.28);
}

.login-input {
  background-color: white !important;
  color: var(--primaryColor) !important;
  border-radius: 10px !important;
  transition: transform 0.2s !important;
  font-family: "Cairo";
}

.profile-user-name {
  font-family: "Cairo";
  font-weight: bold;
  font-size: xx-large;
}

.profile-data {
  font-size: large;
}

.profile-data-web {
  display: inline !important;
}

.profile-data-mob {
  display: none !important;
}

/* .login-input:hover {
  transform: scale(1.15) !important;
  font-family: "Cairo";
} */

.login-input::placeholder {
  color: var(--primaryColor) !important;
  font-family: "Cairo";
}

.main-button {
  background-color: var(--primaryColor) !important;
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  margin-left: 2px;
  margin-right: 2px;
  font-family: "Cairo";
}

.main-button:disabled {
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--mainGrey) !important;
  color: var(--darkGrey) !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
}

.main-button:checked {
  transform: scale(1.01);
}

.main-button-cancel {
  margin-left: 2px;
  margin-right: 2px;
  background-color: red !important;
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
}

.main-button-cancel:disabled {
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--mainGrey) !important;
  color: var(--darkGrey) !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
}

.main-button-accept {
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--acceptGreen) !important;
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
}

.main-button-accept:disabled {
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--mainGrey) !important;
  color: var(--darkGrey) !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
}

.main-button-selected {
  background-color: var(--darkGrey) !important;
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  margin-left: 2px;
  margin-right: 2px;
  font-family: "Cairo";
}

.home-tasks {
  font-family: "Cairo";
  background-color: var(--secColor);
  border-radius: 15px;
  box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -webkit-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -moz-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
}
.main-text-dark {
  font-family: "Cairo";
  font-weight: bold;
  font-size: 15px;
  color: var(--primaryColor);
}

.task-text {
  font-size: 25px;
  font-weight: bold;
  color: var(--primaryColor);
  font-family: "Cairo";
}

.side_col {
  font-family: "Cairo";
  width: fit-content !important;
}

.hiddenNavbar{
  display: none;
}
.task_form {
  background-color: var(--secColor);
  margin-top: 20px;
  padding: 25px;
  overflow-y: auto;
  box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -webkit-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -moz-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  font-family: "Cairo";
}

.task-item {
  background-color: var(--secColor);
  margin-top: 16px;
  padding: 25px;
  overflow-y: auto;
  box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -webkit-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -moz-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  /* border-radius: 30px !important; */
  font-family: "Cairo";
}

.sec-button {
  background-color: white !important;
  color: var(--primaryColor) !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
}

/* .sec-button:hover {
  transform: scale(1.15) !important;
  font-family: "Cairo";
} */

.task-input {
  background-color: white !important;
  color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor) !important;
  /* border-radius: 10px !important; */
  font-family: "Cairo";
}

.main-text {
  color: var(--primaryColor);
  font-size: 15px;
  font-weight: bold;
  font-family: "Cairo";
  word-wrap: break-word;
}

.category-item {
  box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.75);
  background-color: var(--primaryColor);
  border-radius: 10px;
  font-family: "Cairo";
}
.reply-box {
  box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  font-family: "Cairo";
}
.category-item:hover {
  transform: scale(1.1) !important;
  font-family: "Cairo";
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  font-family: "Cairo";
}

.react-tagsinput--focused {
  border-color: var(--primaryColor) !important;
  font-family: "Cairo";
}

.react-tagsinput-tag {
  background-color: var(--primaryColor) !important;
  border-radius: 2px;
  border: 1px solid var(--primaryColor) !important;
  color: white;
  display: inline-block;
  border-radius: 10px;
  font-family: sans-serif;
  font-size: bold;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  font-family: "Cairo";
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  font-family: "Cairo";
}

.react-tagsinput-tag a::before {
  content: " ×";
  font-family: "Cairo";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
  font-family: "Cairo";
}

.reply-title {
  color: var(--primaryColor);
  font-family: "Cairo";
  word-wrap: break-word;
}

.head-text {
  color: var(--primaryColor);
  font-family: "Cairo";
  font-weight: bolder;
  font-size: xx-large;
}

#top-nav-link {
  color: white !important;
  padding: 0px !important;
  font-size: 20px;
}

.toggler-nav {
  display: none;
}

.main-page {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 !important;
}

.nav_text {
  margin-left: 10px;
}

.contact-item {
  border: 3px solid var(--primaryColor) !important;
  border-radius: 10px !important;
  padding: 15px;
}

.active {
  background-color: var(--mainWhite) !important;
  color: var(--primaryColor) !important;
  padding: 2px;
}

.pag-link {
  color: var(--primaryColor) !important;
  background-color: var(--mainGrey) !important;
}

.pag-link-curr {
  color: var(--mainGrey) !important;
  background-color: var(--primaryColor) !important;
}

.invalid-entry-message {
  color: var(--secColor);
}

input[type="file"]::file-selector-button {
  background-color: var(--primaryColor) !important;
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
}

input[type="file"]::file-selector-button:hover {
  background-color: var(--primaryColor) !important;
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  font-family: "Cairo";
  cursor: pointer;
}

#side-toggler {
  display: block;
}

#side-toggler2 {
  display: none;
  margin: 0px !important;
}

.tag {
  color: black !important;
  background-color: #1f96e66b !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.App {
  background-color: var(--appGrey);

  font-family: "cairo" !important;
}

.template-box {
  background-color: var(--secColor);
  min-height: 100px;
  max-height: 600px;
  width: 400px;
  box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -webkit-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -moz-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  overflow-x: hidden;
  overflow-y: hidden;
}

.template-head-text {
  font-family: "cairo";
  font-size: x-large;
}

.template-text {
  font-family: "cairo";
  word-wrap: break-word;
  white-space: pre-line;
}

.media-box {
  max-height: 200px;
  overflow-x: auto;
  overflow-y: auto;
}

.template-desc-box {
  border: 1px dotted var(--darkGrey) !important;
  padding: 10px !important;
  border-radius: 5px !important;
  word-wrap: break-word !important;
  max-width: 500px !important;
  max-height: 200px !important;
  overflow-y: auto !important;
}

.task-desc-box {
  border: 1px dotted var(--darkGrey) !important;
  padding: 10px !important;
  border-radius: 5px !important;
  word-wrap: break-word !important;
  max-width: 500px !important;
  min-height: 50px !important;
  max-height: 100px !important;
  overflow-y: auto !important;
}

.quick-profile {
  word-wrap: break-word;
}

.rounded-button {
  width: 50px !important;
  height: 50px !important;
  border-radius: 25px !important;
  margin: 0px !important;
  padding: 10px !important;
}

.file-display {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px !important;
  width: 75px !important;
  height: 75px !important;
  cursor: pointer;
}

.link {
  color: var(--mainWhite);
  text-decoration: underline;
}

.events {
  padding: 0px;
  list-style: none;
  margin: 0px;
}

.notification {
  border-radius: 15px !important;
  background-color: var(--secColor);
  min-height: 100px;
  max-height: 600px;
  box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -webkit-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -moz-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
}

.notification:hover {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--mainGrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--hoverColor);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor);
}

.overview-labels {
  font-family: "cairo";
  font-size: large;
}

.overview-variables {
  font-family: "cairo";
  font-size: large;
}

.ant-tag-checkable {
  background-color: var(--appGrey) !important;
  color: black !important;
}

.ant-tag-checkable-checked {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.infinite_scroller {
  height: 300px !important;
}

.rotator {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.chat_items {
  overflow-y: auto;
  overflow-x: hidden;
  height: 80vh;
}

.chatsList {
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.dropdown_chat {
  border-radius: 10px !important;
}

.dropdown_chat_item {
  font-size: 19px;
  color: var(--darkGrey);
}

audio {
  width: 400px;
}

.chat-date {
  font-weight: normal;
  font-size: 13px;
}

.google-button {
  border-radius: 20px !important;
  padding-left: 15px !important;
}

.black_bold_text {
  color: black;
  font-weight: bold;
  font-family: "Cairo";
  font-size: 15px;
}
.rounded_button {
  height: 50px !important;
  line-height: 5px !important;
  width: 50px !important;
  font-size: 1.5em !important;
  align-items: center;
  text-align: center;
  font-weight: bold !important;
  border-radius: 50% !important;
  background-color: var(--primaryColor) !important;
  color: white !important;
  text-align: center !important;
  cursor: pointer !important;
  border: none !important;
}
.rounded_button_white {
  height: 50px !important;
  line-height: 5px !important;
  width: 50px !important;
  font-size: 1.5em !important;
  align-items: center;
  text-align: center;
  font-weight: bold !important;
  border-radius: 50% !important;
  background-color: white !important;
  color: var(--darkGrey) !important;
  text-align: center !important;
  cursor: pointer !important;
  border: none !important;
  box-shadow: 5px 1px 5px 0px rgba(202, 199, 199, 0.144);
  -webkit-box-shadow: 5px 1px 5px 0px rgba(202, 199, 199, 0.144);
  -moz-box-shadow: 5px 1px 5px 0px rgba(202, 199, 199, 0.144);
}
.messageComponent {
  margin: 10px 10px 10px 10px !important;
  padding: 5px 9px 5px 9px !important;
  transition: transform 0.2s !important;
}
.sysMessageComponent {
  margin: 10px 10px 10px 10px !important;
  padding: 5px 9px 5px 9px !important;
  color: var(--primaryColor);
}
/* .messageComponent:hover {
  transform: scale(1.005) !important;
} */

.galleryComponent {
  border-radius: 10px;
  padding: 10px;
  background-color: white;
}

.contactText {
  color: var(--darkGrey);
  font-weight: bold;
  font-family: Cairo;
  font-size: 20px;
}

.noMediaText {
  color: var(--darkGrey);
  font-weight: bold;
  font-family: Cairo;
  font-size: 14px;
}

.error-message {
  background-color: white;
  color: red;
  border-radius: 15px;
  text-align: center;
  padding: 7px;
}
.chat-user-name {
  font-size: 15px;
  color: var(--primaryColor);
  max-width: 15ch !important;
}
.chat-last-message {
  opacity: 0.8;
  color: var(--darkGrey);
  max-width: 20ch;
  font-weight: bold;
  font-size: 12px;
}

.chatMessageDate {
  font-size: 11px;
  text-align: start;
  min-width: 11ch;
  color: var(--darkGrey);
  opacity: 0.8;
}
.chat-search {
  border-radius: 12px !important;
  background-color: var(--mainGrey) !important;
}
.chatSender {
  background-color: white;
  border-radius: 12px;
  align-items: center;
}

.chatFilter {
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
}

.notificationItem {
  background-color: white;
  border-radius: 30px;
  box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -webkit-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  -moz-box-shadow: 2px 3px 5px 0px rgba(202, 199, 199, 0.19);
  margin-left: 20% !important;
  margin-right: 20% !important;
  margin-top: 20px !important;
  padding: 20px !important;
}

.notification-text-actor {
  word-wrap: break-word;
  word-break: break-word;
  font-weight: bolder;
  font-family: "Cairo";
  font-size: 16px;
  color: var(--primaryColor);
}
.notification-text {
  font-size: 14px;
  font-weight: bold;
  opacity: 0.7;
  cursor: pointer;
}

.setting_card_box {
  background-color: white;
  padding: 15px;
  padding-top: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}
.forwardModal .ant-modal-content {
  border-radius: 20px;
}
.forwardCheckBox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 20%;
}

.menu__item {
  margin-top: -13px !important;
  font-weight: normal;
  font-family: "Cairo";
}
.white-text {
  color: white;
  font-family: "Cairo";
}

.chat_input_message {
  border: none;
}
.chat_input_message:focus {
  border: none;
  outline: none;
}
audio::-webkit-media-controls-panel {
  background-color: white;
  color: var(--primaryColor);
}

audio::-webkit-media-controls-mute-button {
  color: var(--primaryColor);
}

.select_check_label input {
  position: relative !important;
  display: none;
  cursor: pointer;
}
.select_check_label span {
  width: 20px !important;
  height: 20px !important;
  display: block !important;
  background: white !important;
  border-radius: 20px;
  cursor: pointer;
}

.select_check_label input:checked + span {
  background: var(--primaryColor) !important;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .profile-data-web {
    display: none !important;
  }

  .profile-data-mob {
    display: inline !important;
  }

  audio {
    width: 150px;
  }
  .notificationItem {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .infinite_scroller {
    height: 250px !important;
    overflow: hidden;
    padding-bottom: 20px;
  }
  .main-side-bar {
    height: auto;
    border-radius: 0 !important;
    border: 0px !important;
    margin-top: 0px !important;
    padding: 0px !important;
    box-shadow: 1px 10px 11px -3px rgba(202, 199, 199, 0.83);
    -webkit-box-shadow: 1px 10px 11px -3px rgba(202, 199, 199, 0.83);
    -moz-box-shadow: 1px 10px 11px -3px rgba(202, 199, 199, 0.83);
  }

  .toggler-nav {
    display: block;
  }

  #navbar {
    margin-bottom: 0px !important;
    border: 0px !important;
  }

  .main-page {
    padding-bottom: 50px;
    margin-bottom: 50px;
    margin-top: 0px !important;
  }

  #side-toggler {
    display: inline;
  }

  .scroller-side-bar {
    overflow-y: auto;
    max-height: 40vh !important;
    width: 100vw !important;
  }
}
